/* // Get JSON from Jan, and use this: https://stackblitz.com/edit/js-f6k1kk?file=index.html,index.js,style.css
// Every hap should have its own set of --brand variables, and then we can use the --brand-500 for the main color, and --brand-100 for the lightest color, and --brand-900 for the darkest color. */
body {
    --accordion-background: var(--brand-20);
    --admin-brand-100: #d9eee8;
    --admin-brand-200: #b4ddd1;
    --admin-brand-20: #f4faf8;
    --admin-brand-300: #8eccb9;
    --admin-brand-400: #69bba2;
    --admin-brand-500-5: #43aa8b0d;
    --admin-brand-500: #43aa8b;
    --admin-brand-600: #36886f;
    --admin-brand-700: #286653;
    --admin-brand-800: #1b4438;
    --admin-brand-900: #0d221c;
    --admin-brand-950: #040a08;
    --assist-brand-100: #d7e3ff;
    --assist-brand-200: #afc7ff;
    --assist-brand-20: #f3f7ff;
    --assist-brand-300: #87aaff;
    --assist-brand-400: #5f8eff;
    --assist-brand-500-5: #3772ff0d;
    --assist-brand-500: #3772ff;
    --assist-brand-600: #2c5bcc;
    --assist-brand-700: #214499;
    --assist-brand-800: #162e66;
    --assist-brand-900: #0b1733;
    --assist-brand-950: #03070f;
    --background-body-accent-inverted: var(--gray-800);
    --background-body-accent: var(--gray-200);
    --background-body-inverted: var(--gray-950);
    --background-body: var(--gray-100);
    --background-danger-accent: var(--red-200);
    --background-danger: var(--red-500);
    --background-disabled: var(--gray-300);
    --background-divider: var(--gray-300);
    --background-warning-accent: var(--orange-200);
    --background-warning: var(--orange-500);
    --body-content-grid: var(--spacing-24);
    --body-content-radius: var(--radius-2xl);
    --body-number: 0;
    --button-button-normal-radius: var(--radius-lg);
    --button-button-small-radius: var(--radius-md);
    --button-danger-background-color-end: var(--red-shade-400);
    --button-danger-background-color-start: var(--red-500);
    --button-danger-link-outline: var(--red-100);
    --button-danger-link-text: var(--red-500);
    --button-danger-text: var(--background-body);
    --button-primary-background-color-end: var(--brand-600);
    --button-primary-background-color-start: var(--brand-500);
    --button-primary-link-outline: var(--brand-100);
    --button-primary-link-text: var(--brand-500);
    --button-primary-text: var(--background-body);
    --button-ring: var(--gray-300);
    --button-secondary-background-color-end: var(--brand-900);
    --button-secondary-background-color-start: var(--gray-800);
    --button-secondary-link-outline: var(--gray-200);
    --button-secondary-link-text: var(--brand-900);
    --button-secondary-text: var(--background-body);
    --button-text-disabled: var(--gray-500);
    --calendar-border: var(--gray-200);
    --calendar-event-1: #edcf83;
    --calendar-event-2: #2e777b;
    --calendar-event-3: #a4d6ca;
    --gray-1000: #000000;
    --gray-100: #ffffff;
    --gray-200: #e8e7e7;
    --gray-300: #d2cfd0;
    --gray-400: #bbb7b8;
    --gray-500: #a49fa1;
    --gray-600: #60575a;
    --gray-700: #493f42;
    --gray-800: #33272b;
    --gray-900: #1c0f13;
    --gray-950: #170c10;
    --green-100: #e7fbe9;
    --green-200: #b6f3bc;
    --green-300: #85eb8f;
    --green-400: #55e362;
    --green-500: #24db35;
    --green-600: #1caa2a;
    --green-700: #147a1e;
    --green-800: #0c4912;
    --green-900: #041806;
    --grid-odd-row-background: var(--brand-500-5);
    --input-background: var(--gray-100);
    --input-border-error: var(--red-200);
    --input-border: var(--gray-300);
    --input-checkbox-radius: var(--radius-sm);
    --input-disabled: var(--background-disabled);
    --input-focus: var(--brand-500);
    --input-input-radius: var(--radius-md);
    --input-primary-default: var(--brand-200);
    --input-radio-radius: var(--radius-xl);
    --input-switch-container-radius: var(--spacing-12);
    --input-switch-value-radius: var(--spacing-10);
    --input-text-disabled: var(--gray-500);
    --input-text-placeholder-error: var(--red-300);
    --input-text-placeholder: var(--text-description);
    --input-text-value-error: var(--text-danger);
    --input-text-value: var(--text-paragraph);
    --menu-menu-background: var(--gray-900);
    --menu-menu-divider: var(--gray-700);
    --menu-menu-item-active-background: var(--gray-800);
    --menu-menu-item-active-icon: var(--brand-500);
    --menu-menu-text: var(--gray-100);
    --menu-menu-unactive-text: var(--gray-300);
    --modal-modal-background: var(--gray-100);
    --navigation-brand-100: #e6cee8;
    --navigation-brand-200: #cd9dd0;
    --navigation-brand-20: #f8f0f8;
    --navigation-brand-300: #b46db9;
    --navigation-brand-400: #9b3ca1;
    --navigation-brand-500-5: #820b8a0d;
    --navigation-brand-500: #820b8a;
    --navigation-brand-600: #68096e;
    --navigation-brand-700: #4e0753;
    --navigation-brand-800: #340437;
    --navigation-brand-900: #1a021c;
    --navigation-brand-950: #080108;
    --orange-100: #ffe7cf;
    --orange-200: #ffcfa0;
    --orange-300: #ffb870;
    --orange-400: #ffa041;
    --orange-500: #ff8811;
    --orange-600: #cc6d0e;
    --orange-700: #99520a;
    --orange-800: #663607;
    --orange-900: #331b03;
    --radius-2xl: 36px;
    --radius-lg: 16px;
    --radius-md: 10px;
    --radius-sm: 6px;
    --radius-xl: 24px;
    --radius-xs: 2px;
    --red-100: #fcd8d7;
    --red-200: #f9b2af;
    --red-300: #f58b86;
    --red-400: #f2655e;
    --red-500: #ef3e36;
    --red-600: #bf322b;
    --red-700: #8f2520;
    --red-800: #601916;
    --red-900: #300c0b;
    --red-shade-400: #eb1f12;
    --red-shade-600: #cf1c10;
    --service-brand-100: #e4f4dc;
    --service-brand-200: #cae9b9;
    --service-brand-20: #f7fcf5;
    --service-brand-300: #afdd95;
    --service-brand-400: #95d272;
    --service-brand-500-5: #7ac74f0d;
    --service-brand-500: #7ac74f;
    --service-brand-600: #629f3f;
    --service-brand-700: #49772f;
    --service-brand-800: #315020;
    --service-brand-900: #182810;
    --service-brand-950: #070c05;
    --spacing-10: 10px;
    --spacing-12: 12px;
    --spacing-16: 16px;
    --spacing-24: 24px;
    --spacing-36: 36px;
    --spacing-48: 48px;
    --spacing-6: 6px;
    --spacing-8: 8px;
    --spacing-button-normal-padding: var(--spacing-16);
    --spacing-button-small-padding: var(--spacing-8);
    --spacing-button-spacing: var(--spacing-10);
    --spacing-grid-md: var(--spacing-24);
    --spacing-grid-sm: var(--spacing-12);
    --spacing-safe-area-desktop: var(--spacing-36);
    --spacing-safe-area-mobile: var(--spacing-16);
    --spacing-switch-item-padding: var(--spacing-8);
    --tabs-active-background: var(--brand-20);
    --tabs-active-text: var(--brand-500);
    --tabs-unactive-text: var(--gray-700);
    --text-danger: var(--red-500);
    --text-description: var(--gray-600);
    --text-heading: var(--gray-900);
    --text-help: var(--yellow-500);
    --text-link: var(--brand-500);
    --text-paragraph: var(--gray-800);
    --text-subheading: var(--gray-800);
    --text-success: var(--green-600);
    --text-warning: var(--orange-500);
    --timeline-danger: var(--red-500);
    --timeline-success: var(--green-600);
    --yellow-100: #fbf5d7;
    --yellow-200: #f6ebae;
    --yellow-300: #f2e184;
    --yellow-400: #edd65b;
    --yellow-500: #e9cc32;
    --yellow-600: #baa328;
    --yellow-700: #8b7a1e;
    --yellow-800: #5d5114;
    --yellow-900: #2e280a;
}

html[data-theme='dark'] {
    --accordion-background: var(--brand-950);
    --background-body-accent-inverted: var(--gray-200);
    --background-body-accent: var(--gray-800);
    --background-body-inverted: var(--gray-100);
    --background-body: var(--gray-950);
    --background-danger-accent: var(--red-800);
    --background-danger: var(--red-500);
    --background-disabled: var(--gray-700);
    --background-divider: var(--gray-700);
    --background-warning-accent: var(--orange-800);
    --background-warning: var(--orange-500);
    --button-danger-background-color-end: var(--red-shade-600);
    --button-danger-background-color-start: var(--red-500);
    --button-danger-link-outline: var(--red-800);
    --button-danger-link-text: var(--red-500);
    --button-danger-text: var(--background-body);
    --button-primary-background-color-end: var(--brand-400);
    --button-primary-background-color-start: var(--brand-500);
    --button-primary-link-outline: var(--brand-800);
    --button-primary-link-text: var(--brand-500);
    --button-primary-text: var(--background-body);
    --button-ring: var(--gray-700);
    --button-secondary-background-color-end: var(--brand-100);
    --button-secondary-background-color-start: var(--gray-100);
    --button-secondary-link-outline: var(--gray-700);
    --button-secondary-link-text: var(--brand-200);
    --button-secondary-text: var(--background-body);
    --button-text-disabled: var(--gray-500);
    --calendar-border: var(--gray-800);
    --calendar-event-1: #5a4c28;
    --calendar-event-2: #a9dee1;
    --calendar-event-3: #3a6359;
    --grid-odd-row-background: var(--brand-500-5);
    --input-background: var(--gray-1000);
    --input-border-error: var(--red-200);
    --input-border: var(--gray-700);
    --input-disabled: var(--background-disabled);
    --input-focus: var(--brand-500);
    --input-primary-default: var(--brand-800);
    --input-text-disabled: var(--gray-500);
    --input-text-placeholder-error: var(--red-700);
    --input-text-placeholder: var(--text-description);
    --input-text-value-error: var(--text-danger);
    --input-text-value: var(--text-paragraph);
    --menu-menu-background: var(--gray-900);
    --menu-menu-divider: var(--gray-700);
    --menu-menu-item-active-background: var(--gray-800);
    --menu-menu-item-active-icon: var(--brand-500);
    --menu-menu-text: var(--gray-100);
    --menu-menu-unactive-text: var(--gray-300);
    --modal-modal-background: var(--gray-900);
    --tabs-active-background: var(--brand-900);
    --tabs-active-text: var(--brand-500);
    --tabs-unactive-text: var(--gray-300);
    --text-danger: var(--red-500);
    --text-description: var(--gray-400);
    --text-heading: var(--gray-100);
    --text-help: var(--yellow-500);
    --text-link: var(--brand-500);
    --text-paragraph: var(--gray-100);
    --text-subheading: var(--gray-200);
    --text-success: var(--green-500);
    --text-warning: var(--orange-500);
    --timeline-danger: var(--red-700);
    --timeline-success: var(--green-700);
}

.desktop-2xl-h1 {
    font-size: 44px;
    font-family: 'Basic Sans';
    font-weight: Bold;
    line-height: 139.9999976158142%;
    letter-spacing: 0;
}
.desktop-xl-h2 {
    font-size: 32px;
    font-family: 'Basic Sans';
    font-weight: Bold;
    line-height: 139.9999976158142%;
    letter-spacing: 0;
}
.desktop-l-h3 {
    font-size: 21px;
    font-family: 'Basic Sans';
    font-weight: Bold;
    line-height: 139.9999976158142%;
    letter-spacing: 0;
}
.desktop-normal-p {
    font-size: 16px;
    font-family: 'Nunito';
    font-weight: Regular;
    line-height: 139.9999976158142%;
    letter-spacing: 0;
}
.desktop-normal-bold-strong {
    font-size: 16px;
    font-family: 'Nunito';
    font-weight: Bold;
    line-height: 139.9999976158142%;
    letter-spacing: 0;
}
.desktop-normal-link-a {
    font-size: 16px;
    font-family: 'Nunito';
    font-weight: Bold;
    line-height: 139.9999976158142%;
    letter-spacing: 0;
    text-transform: ORIGINAL;
    text-decoration: UNDERLINE;
}
.desktop-button {
    font-size: 16px;
    font-family: 'Nunito';
    font-weight: Bold;
    line-height: 139.9999976158142%;
    letter-spacing: 12;
    text-transform: UPPER;
    text-decoration: NONE;
}
.desktop-sm-label {
    font-size: 14px;
    font-family: 'Nunito';
    font-weight: Bold;
    line-height: 139.9999976158142%;
    letter-spacing: 0;
}
.desktop-xs-descriptive {
    font-size: 12px;
    font-family: 'Nunito';
    font-weight: Regular;
    line-height: 139.9999976158142%;
    letter-spacing: 0;
}
.desktop-xs-bold-descrioptive-strong {
    font-size: 12px;
    font-family: 'Nunito';
    font-weight: Bold;
    line-height: 139.9999976158142%;
    letter-spacing: 0;
}
.desktop-xs-link-xs-a {
    font-size: 12px;
    font-family: 'Nunito';
    font-weight: Bold;
    line-height: auto;
    letter-spacing: 0;
    text-decoration: underline;
}

:root {
    --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.15);
    --shadow-normal: 0 1px 3px 0 rgb(0 0 0 / 0.25), 0 1px 2px -1px rgb(0 0 0 / 0.25);
    --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.25), 0 2px 4px -2px rgb(0 0 0 / 0.25);
    --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.25), 0 4px 6px -4px rgb(0 0 0 / 0.25);
    --shadow-xl: 0 20px 25px -5px rgb(0 0 0 / 0.25), 0 8px 10px -6px rgb(0 0 0 / 0.25);
    --shadow-2xl: 0 25px 50px -12px rgb(0 0 0 / 0.4);
    --shadow-inner: inset 0 2px 4px 0 rgb(0 0 0 / 0.15);
}
