$crayola_blue-100: rgba(215 227 255);
$crayola_blue-200: rgba(175 199 255);
$crayola_blue-300: rgba(135 170 255);
$crayola_blue-400: rgba(95 142 255);
$crayola_blue-500: rgba(55 114 255);
$crayola_blue-600: rgba(44 91 204);
$crayola_blue-700: rgba(33 68 153);
$crayola_blue-800: rgba(22 46 102);
$crayola_blue-900: rgba(11 23 51);

$magenta_100: rgb(230, 206, 232);
$magenta_200: rgb(205, 157, 208);
$magenta_300: rgb(180, 109, 185);
$magenta_400: rgb(155, 60, 161);
$magenta_500: rgb(130, 11, 138);
$magenta_600: rgb(104, 9, 110);
$magenta_700: rgb(78, 7, 83);
$magenta_800: rgb(52, 4, 55);
$magenta_900: rgb(26, 2, 28);

// Common colors
$color_common_main: $crayola_blue-600;
$color_common_main_hover: $crayola_blue-500;
$color_common_white: #ffffff;
$color_common_white_hover: #ececec;
$color_common_orange: #ea8f08;
$color_common_lightgray: #b8b7b7;
$color_common_black: #000000;

// Event colors
$color_event_hover: #fee487;
$color_event_inactive: #e0e0e0;
$color_event_available: #ffbc97;
$color_event_single-captive: #eb5757;
$color_event_single-free: #4a9f5d;
$color_event_multiple-free: #b3e8be;
$color_event_multiple-captive: #f9b9b9;

// Layout colors
$color_top-menu: #233c5a;
$color_left-menu: #e8e8e8;
$color_left-menu-group: #e0e0e0;
$color_second-menu: #f5f5f5;
$color_content: #ffffff;
$color_map: #e5e5e5;

// Event-free colors
$color_free_100: #06a10b;
$color_free_075: #1d800e;
$color_free_050: #06a10b;
$color_free_025: #46cb18;
$color_free_001: #66e33b;
$color_free_000: #c61a1a;

// $blue: #233C5A;
// $orange: #ea8f08;
// $white: #ffffff;
// $lightgray: #e5e5e5;
// $darkergray: #d9d9d9;

$positive: #33b630;
$positive-hover: #229120;
$negative: #ce2828;
$negative-hover: #a11313;

$description-gray: #8a8a8a;

$color_cancel: #eb5757;

$table-border-color: rgb(156, 156, 156);
$description-border-color: rgba(156, 156, 156, 0.2);

//HTML elements colors
$color_button_blue: #3a82b7;
$color_button_blue_hover: rgb(17, 76, 117);
$color_button_red: #d91111;
$color_button_red_hover: rgb(145, 17, 17);

$service-color: #02a4d7;
$service-color-hover: #037b9d;

$color_common_main-a-15: rgba(35, 60, 90, 0.15);

$primary-600: $crayola_blue-700;
$primary-500: $crayola_blue-600;
$primary-400: $crayola_blue-500;
$primary-100: $crayola_blue-200;

// STATE COLORS
$error-900: #540707;
$error-600: #a52626;
$error-500: #c91f1f;
$error-400: #f34f4f;
$error-100: #eec2c2;
$warning-500: #f68b2d;
$success-900: #125505;
$success-600: #347428;
$success-500: #19ac39;
$success-400: #6acc57;
$success-100: #c8efc1;

// NEUTRAL COLORS
$neutral-100: #ffffff;
$neutral-200: #efefef;
$neutral-300: #dcdcdc;
$neutral-400: #cacaca;
$neutral-500: #9b9b9b;
$neutral-700: #444444;
$neutral-900: #000000;
